import React from 'react';
import { Heading } from '../../components';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

function getDurationDescription(value) {
  switch (value) {
    case '1':
      return '1 day';
    case '2':
      return '2 days';
    case '5':
      return '2-5 days';
    case '7':
      return '5-7 days';
    case '14':
      return '1-2 weeks';
    case '28':
      return '2-4 weeks';
    case '30':
      return '1 month';
    case '60':
      return 'More than 1 month';
    default:
      return null;
  }
}

const SectionDurationMaybe = props => {
  const { duration, heading, showAsIngress = false } = props;
  const textClass = showAsIngress ? css.ingress : css.text;

  const text = getDurationDescription(duration);
  const content = richText(text, {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
    breakChars: '/',
  });

  return text ? (
    <div className={css.sectionText}>
      {heading ? (
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {heading}
        </Heading>
      ) : null}
      <p className={textClass}>{content}</p>
    </div>
  ) : null;
};

export default SectionDurationMaybe;
