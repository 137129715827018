import React from 'react';
import { Heading } from '../../components';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const SectionCertificateMayBe = props => {
  const { text, heading, showAsIngress = false } = props;
  const textClass = showAsIngress ? css.ingress : css.text;

  const certName = decodeURI(text?.certificateName);
  return text ? (
    <div className={css.sectionText}>
      {heading ? (
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {heading}
        </Heading>
      ) : null}
      <a href={text?.url} target="_blank" className={textClass}>
        {certName}
      </a>
    </div>
  ) : null;
};

export default SectionCertificateMayBe;
