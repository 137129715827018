import React from 'react';
import { Heading } from '../../components';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const SectionCheckBoxGroupMaybe = props => {
  const { id, heading, options, publicData } = props;

  const value = publicData[id];

  return value ? (
    <>
      <SectionMultiEnumMaybe key={id} heading={heading} options={options} selectedOptions={value} />
    </>
  ) : null;
};

export default SectionCheckBoxGroupMaybe;
