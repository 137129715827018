import React from 'react';
import { IoMdStarOutline } from 'react-icons/io';
import { ReactComponent as FullStar } from '../../assets/svg/fullStar.svg';
import { ReactComponent as HalfStar } from '../../assets/svg/halfStar.svg';
import { ReactComponent as EmptyStar } from '../../assets/svg/emptyStar.svg';
import IconReviewStar from '../IconReviewStar/IconReviewStar';
import css from './StarRatring.module.css';
function StarRating(props) {
  const { rating } = props;
  const numberOfStars = 5;
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;

  const stars = [];
  for (let i = 0; i < fullStars; i++) {
    stars.push(<FullStar key={i + 10 + numberOfStars} className={css.filled} />);
  }

  if (hasHalfStar) {
    stars.push(<HalfStar className={css.HalfStar} />);
    //    stars.push(<IconReviewStar  className={css.half}/>);
  }

  const emptyStars = numberOfStars - stars.length;
  for (let i = 0; i < emptyStars; i++) {
    stars.push(<EmptyStar className={css.emptyStar} key={i} />);
  }

  return <div className={css.starsDiv}>{stars}</div>;
}

export default StarRating;
