import React from 'react';
import { Heading } from '../../components';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const SectionCastingDateMaybe = props => {
  const { heading, showAsIngress = false, listingCategory, dateNotDeclared, date } = props;
  const textClass = showAsIngress ? css.ingress : css.text;

  const text = dateNotDeclared ? 'Date not declared' : new Date(date).toDateString();
  const content = richText(text, {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
    breakChars: '/',
  });

  return listingCategory === 'castingCall' ? (
    <div className={css.sectionText}>
      {heading ? (
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {heading}
        </Heading>
      ) : null}
      <p className={textClass}>{content}</p>
    </div>
  ) : null;
};

export default SectionCastingDateMaybe;
