import React from 'react';
import { socialMediaLinks } from '../../config/configListing';
import { Heading } from '../../components';
import css from './ListingPage.module.css';

const SectionSocialMediaMaybe = props => {
  const { listing, heading } = props;
  const socialMediaLinksFormListing = listing?.attributes?.publicData?.socialMediaLinks || {};
  const linksData = Object.keys(socialMediaLinksFormListing).map(s => ({
    url: socialMediaLinksFormListing[s],
    ...socialMediaLinks.find(so => so.key === s),
  }));
  if (linksData?.length === 0) return null;
  const linksMaybe =
    linksData?.length > 0
      ? linksData.map(social => {
          const url = social.url?.startsWith('https')
            ? social.url
            : social.url?.startsWith('www.')
            ? `https://${social.url}`
            : `https://www.${social.url}`;
          return (
            <li key={social.key}>
              <a target="_blank" href={url}>
                <img src={social.logo} alt={social.key} />
              </a>
            </li>
          );
        })
      : null;
  return (
    <div className={css.sectionText}>
      {heading ? (
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {heading}
        </Heading>
      ) : null}
      <ul className={css.socials}>{linksMaybe}</ul>
    </div>
  );
};

export default SectionSocialMediaMaybe;
