import React, { useState } from 'react';
import { Heading, PrimaryButton } from '../../components';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';
import { isEmpty } from 'lodash';
import { types as sdkTypes } from '../../util/sdkLoader';
import { priceData } from './ListingPage.shared';
import { useConfiguration } from '../../context/configurationContext';
import { ReactComponent as Checked } from '../../assets/icons/checked.svg';
import { ReactComponent as NotChecked } from '../../assets/icons/not-checked.svg';
import classNames from 'classnames';
import { createResourceLocatorString } from '../../util/routes';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { LISTING_STATE_DRAFT, LISTING_STATE_PENDING_APPROVAL } from '../../util/types';

const { Money } = sdkTypes;
const ANIMAL_CONTENT_CREATOR = 'animalContentCreator';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const SectionPackageMaybe = props => {
  const {
    text,
    heading,
    showAsIngress = false,
    publicData = {},
    intl,
    history,
    slug,
    listingId,
    listingState,
    search,
    setTab,
    tab,
    viewport,
  } = props;
  const config = useConfiguration();
  const route = useRouteConfiguration();

  const [showFullDescription, setShowFullDescription] = useState({
    basic: false,
    advance: false,
    premium: false,
  });

  const toggleDescription = packageType => {
    setShowFullDescription(prevState => ({
      ...prevState,
      [packageType]: !prevState[packageType],
    }));
  };

  const ALLOWED_ADD_ON_FOR_OTHERS = config.listing.allowedAddonForOthers || [];
  const ALLOWED_ADD_ON_FOR_CONTENT_CREATORS = config.listing.allowedAddOnForContentCreator || [];

  const textClass = showAsIngress ? css.ingress : css.text;
  const content = richText(text, {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
    breakChars: '/',
  });

  const { packageDetail = {}, packageAndOffer, category } = publicData;
  const { basic = {}, advance = {}, premium = {} } = packageDetail;

  const addonToDisplay =
    category === ANIMAL_CONTENT_CREATOR
      ? ALLOWED_ADD_ON_FOR_CONTENT_CREATORS
      : ALLOWED_ADD_ON_FOR_OTHERS;

  const createListingURL = queryParams => {
    const isPendingApproval = listingState === LISTING_STATE_PENDING_APPROVAL;
    const isDraft = listingState === LISTING_STATE_DRAFT;
    const variant = isDraft
      ? LISTING_PAGE_DRAFT_VARIANT
      : isPendingApproval
      ? LISTING_PAGE_PENDING_APPROVAL_VARIANT
      : null;

    const linkProps =
      isPendingApproval || isDraft
        ? {
            name: 'ListingPageVariant',
            params: {
              id: listingId,
              slug,
              variant,
            },
          }
        : {
            name: 'ListingPage',
            params: { id: listingId, slug },
          };

    return createResourceLocatorString(linkProps.name, route, linkProps.params, { ...queryParams });
  };

  const renderPackage = (packageData, packageType) => {
    const description = packageData.description;

    const truncatedDescription = description
      ?.split(' ')
      .slice(0, 50)
      .join(' '); // Displaying first 50 words initially
    const showMoreButton = description?.split(' ').length > 50;

    return (
      <div className={css.pckg}>
        <div className={css.priceContainer}>
          <span className={css.price}>
            {packageData?.price?.amount && packageData?.price?.currency
              ? priceData(
                  new Money(packageData.price.amount, packageData.price.currency),
                  packageData.price.currency,
                  intl
                ).formattedPrice
              : null}
          </span>
          <span className={css.unit}>per day</span>
        </div>
        <div className={css.planTitle}>{packageType}</div>
        <div className={css.planDescription}>
          {showFullDescription[packageType] ? description : truncatedDescription}
          {showMoreButton && (
            <span onClick={() => toggleDescription(packageType)}>
              {showFullDescription[packageType] ? 'Show less' : 'Show more'}
            </span>
          )}
        </div>
      </div>
    );
  };

  const selfWidth = viewport.width;
  const packagesMaybe =
    selfWidth > 575 ? (
      <div className={css.packageDetailContainer}>
        <div className={css.pckgRow}>
          <div className={classNames(css.pckg, css.pckglbl)}>Package</div>
          {renderPackage(basic, 'Basic')}
          {(packageAndOffer === 'two' || packageAndOffer === 'three') &&
            renderPackage(advance, 'Advanced')}
          {packageAndOffer === 'three' && renderPackage(premium, 'Premium')}
        </div>

        {addonToDisplay.map(({ label, key, value }) => (
          <div className={css.pckgRow} key={key}>
            <div className={classNames(css.pckg, css.pckglbl)}>{label}</div>
            <div className={classNames(css.pckg, css.iconContainer)}>
              {basic?.addonData?.includes(key) ? <Checked /> : <NotChecked />}
            </div>
            {(packageAndOffer === 'two' || packageAndOffer === 'three') && (
              <div className={classNames(css.pckg, css.iconContainer)}>
                {advance?.addonData?.includes(key) ? <Checked /> : <NotChecked />}
              </div>
            )}
            {packageAndOffer === 'three' && (
              <div className={classNames(css.pckg, css.iconContainer)}>
                {premium?.addonData?.includes(key) ? <Checked /> : <NotChecked />}
              </div>
            )}
          </div>
        ))}

        <div className={css.pckgRow}>
          <div className={classNames(css.pckg, css.pckglbl)}></div>
          <div className={classNames(css.pckg, css.iconContainer)}>
            <PrimaryButton
              onClick={() => {
                history.push(createListingURL({ p: 'basic' }));
              }}
            >
              Select
            </PrimaryButton>
          </div>
          {(packageAndOffer === 'two' || packageAndOffer === 'three') && (
            <div className={classNames(css.pckg, css.iconContainer)}>
              <PrimaryButton
                onClick={() => {
                  history.push(createListingURL({ p: 'advance' }));
                }}
              >
                Select
              </PrimaryButton>
            </div>
          )}
          {packageAndOffer === 'three' && (
            <div className={classNames(css.pckg, css.iconContainer)}>
              <PrimaryButton
                onClick={() => {
                  history.push(createListingURL({ p: 'premium' }));
                }}
              >
                Select
              </PrimaryButton>
            </div>
          )}
        </div>
      </div>
    ) : (
      <div className={classNames(css.mobpckgrow, css.pckgRow)}>
        <div className={css.pckgsTabs}>
          <button
            className={classNames(css.packageName, { [css.selected]: tab === 'basic' })}
            onClick={e => {
              setTab('basic');
            }}
          >
            Basic
          </button>
          {(packageAndOffer === 'two' || packageAndOffer === 'three') && (
            <button
              className={classNames(css.packageName, { [css.selected]: tab === 'advance' })}
              onClick={e => {
                setTab('advance');
              }}
            >
              Advanced
            </button>
          )}
          {packageAndOffer === 'three' && (
            <button
              className={classNames(css.packageName, { [css.selected]: tab === 'premium' })}
              onClick={e => {
                setTab('premium');
              }}
            >
              Premium
            </button>
          )}
        </div>
        <div className={css.pckgcntnts}>
          {tab === 'premium' && packageAndOffer === 'three' ? (
            <div className={classNames(css.mobpckg, css.pckg)}>
              <div className={css.priceContainer}>
                <span className={css.price}>
                  {premium?.price?.amount && premium?.price?.currency
                    ? priceData(
                        new Money(premium.price?.amount, premium.price?.currency),
                        premium.price?.currency,
                        intl
                      ).formattedPrice
                    : null}
                </span>
                <span className={css.unit}>per day</span>
              </div>
              <div className={classNames(css.mobplantitle, css.planTitle)}>Premium</div>
              <div className={css.planDescription}>{premium.description}</div>
            </div>
          ) : tab === 'advance' && (packageAndOffer === 'two' || packageAndOffer === 'three') ? (
            <div className={classNames(css.mobpckg, css.pckg)}>
              <div className={css.priceContainer}>
                <span className={css.price}>
                  {advance?.price?.amount && advance?.price?.currency
                    ? priceData(
                        new Money(advance?.price?.amount, advance?.price?.currency),
                        advance?.price?.currency,
                        intl
                      ).formattedPrice
                    : null}
                </span>
                <span className={css.unit}>per day</span>
              </div>
              <div className={classNames(css.mobplantitle, css.planTitle)}>Advanced</div>
              <div className={css.planDescription}>{advance.description}</div>
            </div>
          ) : (
            <div className={classNames(css.mobpckg, css.pckg)}>
              <div className={css.priceContainer}>
                <span className={css.price}>
                  {basic?.price?.amount && basic?.price?.currency
                    ? priceData(
                        new Money(basic?.price?.amount, basic?.price?.currency),
                        basic.price?.currency,
                        intl
                      ).formattedPrice
                    : null}
                </span>
                <span className={css.unit}>per day</span>
              </div>
              <div className={classNames(css.mobplantitle, css.planTitle)}>Basic</div>
              <div className={css.planDescription}>{basic.description}</div>
            </div>
          )}
          {addonToDisplay.map(({ label, key, value }) => (
            <div className={css.pckgRow} key={key}>
              {tab === 'basic' && (
                <div className={classNames(css.pckg, css.mobpckg, css.iconContainer)}>
                  {basic?.addonData?.includes(key) ? (
                    <span>
                      <Checked /> {label}
                    </span>
                  ) : (
                    <span>
                      <NotChecked /> {label}
                    </span>
                  )}
                </div>
              )}
              {tab === 'advance' && (
                <div className={classNames(css.pckg, css.mobpckg, css.iconContainer)}>
                  {advance?.addonData?.includes(key) ? (
                    <span>
                      <Checked /> {label}
                    </span>
                  ) : (
                    <span>
                      <NotChecked /> {label}
                    </span>
                  )}
                </div>
              )}
              {tab === 'premium' && (
                <div className={classNames(css.pckg, css.mobpckg, css.iconContainer)}>
                  {premium?.addonData?.includes(key) ? (
                    <span>
                      <Checked /> {label}
                    </span>
                  ) : (
                    <span>
                      <NotChecked /> {label}
                    </span>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  return !isEmpty(packageDetail) ? (
    <div id="packagediv" className={css.sectionText}>
      {heading ? (
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {heading}
        </Heading>
      ) : null}
      {/* <p className={textClass}>{content}</p> */}
      {packagesMaybe}
    </div>
  ) : null;
};

export default SectionPackageMaybe;
