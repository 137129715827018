import React, { useContext, useState } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  SCHEMA_TYPE_MULTI_ENUM,
  SCHEMA_TYPE_TEXT,
  propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ImLink } from 'react-icons/im';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
  parse,
} from '../../util/urlHelpers';
import { convertMoneyToNumber } from '../../util/currency';
import { IoStar } from 'react-icons/io5';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';

import {
  H4,
  Page,
  NamedLink,
  NamedRedirect,
  OrderPanel,
  LayoutSingleColumn,
  AvatarSmall,
  Modal,
  IconClose,
  Button,
  IconSpinner,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  sendInquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
} from './ListingPage.duck';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmitInquiry,
  handleSubmit,
} from './ListingPage.shared';
import ActionBarMaybe from './ActionBarMaybe';
import SectionTextMaybe from './SectionTextMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
import SectionReviews from './SectionReviews';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionGallery from './SectionGallery';
import whitecopy from '../../assets/whitecopy.png';
import { ReactComponent as CertifiedBadgeIcon } from '../../assets/icons/certifiedCheck.svg';
import LinkIcon from '../../assets/LinkIcon.png';
import { ReactComponent as LikeButton } from '../../assets/icons/wishList.svg';
import css from './ListingPage.module.css';
import SectionCertificateMayBe from './SectionCertificateMayBe.js';
import SectionBreedMayBe from './SectionBreedMayBe.js';
import SectionCheckBoxGroupMaybe from './SectionCheckBoxGroupMaybe.js';
import SectionPackageMaybe from './SectionPackageMaybe.js';
import classNames from 'classnames';
import { ToastContext } from '../Toast/ToastContext.js';
import { addOrRemoveFavourites } from '../FavouritesPage/FavouritesPage.duck.js';
import { withViewport } from '../../util/uiHelpers.js';
import SectionCastingBudgetMaybe from './SectionCastingBudgetMaybe.js';
import SectionCastingDateMaybe from './SectionCastingDateMaybe.js';
import SectionReelsMaybe from './SectionReelsMaybe.js';
import SectionDurationMaybe from './SectionDurationMaybe.js';
import SectionSocialMediaMaybe from './SectionSocialMediaMaybe.js';
import StarRating from '../../components/StarRating/StarRating.js';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api.js';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;
const MAX_FAVOURITES = 20;
const { UUID, Money } = sdkTypes;
const ROOT_URL = process.env.REACT_APP_MARKETPLACE_ROOT_URL;

export const ListingPageComponent = props => {
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );

  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    listingConfig: listingConfigProp,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    onInitializeCardPaymentData,
    config,
    routeConfiguration,
    onAddOrRemoveFavourtites,
    adding,
    viewport,
  } = props;
  const searchParams = parse(location.search);
  const [affiliateModal, setAffiliateModal] = useState({
    open: false,
    loading: false,
    error: null,
    url: '',
  });
  const { toggleToast } = useContext(ToastContext);
  const [packageTabMOb, setPackageTabMob] = useState(searchParams?.p || 'basic');
  const onReviewClick = () => {
    const reviewElement = document.getElementById('reviewsSection');
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: reviewElement.offsetTop - 90,
        left: reviewElement.offsetLeft,
        behavior: 'smooth', // Optional: smooth scrolling
      });
    }
  };
  // prop override makes testing a bit easier
  // TODO: improve this when updating test setup
  const listingConfig = listingConfigProp || config.listing;
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;
  const totalReviewRating = metadata?.totalReviewRating || 0;
  const totalReviewContent = metadata?.totalReviewContent || 0;
  const priceAsMoney =
    price instanceof Money ? price : new Money(price?.amount || 0, config.currency);
  const {
    certificate,
    breed,
    castingCallBudget,
    date,
    dateNotDeclared,
    listingCategory,
    reels,
    duration,
    subCategory,
  } = publicData;
  const { url } = reels || {};

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');
  const { formattedPrice } =
    typeof priceAsMoney?.amount === 'number' ? priceData(priceAsMoney, config.currency, intl) : {};

  const commonParams = { params, history, routes: routeConfiguration };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  // Note: this is for inquiry state in booking and purchase processes. Inquiry process is handled through handleSubmit.
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const handleOrderSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      onSubmit(values);
    }
  };

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = priceAsMoney
    ? {
        price: intl.formatNumber(convertMoneyToNumber(priceAsMoney), {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        priceCurrency: priceAsMoney.currency,
      }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));

  // const titleClasses = classNames(titleClassName || css.orderTitle);
  const isClosed = currentListing?.attributes?.state === LISTING_STATE_CLOSED;

  const showClosedListingHelpText = currentListing.id && isClosed;
  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null;

  // const authorLink = (
  //   <NamedLink
  //     className={css.authorNameLink}
  //     name="ListingPage"
  //     params={params}
  //     to={{ hash: '#author' }}
  //   >
  //     {authorDisplayName}
  //   </NamedLink>
  // );

  const authorLink = (
    <NamedLink
      className={css.authorNameLink}
      name="ProfilePage"
      params={{ id: ensuredAuthor?.id?.uuid }}
    >
      {authorDisplayName}
    </NamedLink>
  );
  const isCertifiedListing =
    typeof publicData?.ratings !== 'undefined' &&
    publicData?.ratings !== 'level-0' &&
    // publicData?.ratings === publicData?.lastCertifiedRating &&
    publicData?.isCertificateVerified === 'accepted';
  const currentUserFavourites = currentUser?.attributes?.profile?.privateData?.favourites || [];
  const isFavourite = currentUserFavourites.includes(currentListing.id?.uuid);
  const affiliateToken = currentUser?.attributes?.profile?.privateData?.affiliateToken;
  const isSuperAdmin = currentUser?.attributes?.profile?.publicData?.isSuperAdmin;
  const affiliateUrl = affiliateToken ? ROOT_URL + `/affiliate/${affiliateToken}` : '';
  const favouriteSectionMaybe = (
    <div className={css.favContainer}>
      {affiliateToken ? (
        <button
          className={css.afflnkbtn}
          type="button"
          id="affiliateBtn"
          onClick={() => {
            setAffiliateModal({ ...affiliateModal, open: true, loading: true, error: null });
            affiliateModal.url
              ? setAffiliateModal({ ...affiliateModal, loading: false, open: true })
              : axios
                  .get(
                    `${apiBaseUrl()}/api/affiliate-program/get-affiliate-listing-link?listingId=${
                      rawParams.id
                    }`,
                    { withCredentials: true, headers: { ['redirect-url']: location.pathname } }
                  )
                  .then(res => {
                    setAffiliateModal({
                      ...affiliateModal,
                      url: ROOT_URL + res.data,
                      open: true,
                      loading: false,
                      error: null,
                    });
                  })
                  .catch(err => {
                    setAffiliateModal({
                      ...affiliateModal,
                      open: true,
                      loading: false,
                      error: err,
                    });
                  });
          }}
        >
          <ImLink />
          Affiliate Link
        </button>
      ) : null}
      <button
        type="button"
        className={classNames({
          [css.favload]: !adding,
          [css.favBtn]: !isFavourite,
          [css.unfavBtn]: isFavourite,
        })}
        onClick={e => {
          if (adding) return;
          e.stopPropagation();
          e.preventDefault();
          if (currentUserFavourites?.length === MAX_FAVOURITES && !isFavourite)
            return toggleToast('Favourites limit reached');
          onAddOrRemoveFavourtites(currentListing.id.uuid, currentUserFavourites);
        }}
      >
        <LikeButton />
      </button>
    </div>
  );
  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
      <Modal
        id="copyAffiliatelinkmodal"
        onManageDisableScrolling={onManageDisableScrolling}
        isOpen={affiliateModal.open}
        // isOpen={true}
        closeButtonMessage={<IconClose />}
        titleContent="Affiliate link"
        onClose={() => {
          setAffiliateModal({ ...affiliateModal, open: false });
        }}
      >
        {affiliateModal.loading ? (
          <p>
            Getting your affiliate link <IconSpinner />
          </p>
        ) : affiliateModal.error ? (
          <p>Unable to get your affiliate link. Please try again later</p>
        ) : (
          <div className={css.copyafflinkmodal}>
            <p>Your affiliate link for</p>
            <p>{currentListing?.attributes?.title}</p>
            <div className={css.cpylinkdv}>
              <input type="text" onChange={() => {}} value={affiliateModal.url} />

              <Button
                onClick={() => {
                  typeof navigator !== 'undefined' &&
                    navigator.clipboard
                      .writeText(affiliateModal.url)
                      .then(() => {
                        toggleToast('Affiliate link coppied');
                      })
                      .catch(err => {
                        toggleToast('Affiliate link copy failed');
                      });
                }}
              >
                <img src={whitecopy} />
                Copy link
              </Button>
            </div>
          </div>
        )}
      </Modal>
      <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
        <div className={css.contentWrapperForProductLayout}>
          <div className={css.mainColumnForProductLayout}>
            {currentListing.id ? (
              <ActionBarMaybe
                className={css.actionBarForProductLayout}
                isOwnListing={isOwnListing}
                listing={currentListing}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingPathParamType,
                  tab: listingTab,
                }}
              />
            ) : null}
            <div className={css.orderHeading}>
              <div className={classNames(css.titlewithfv, css.orderPanelTitle)}>
                <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                {favouriteSectionMaybe}
              </div>
              {subTitleText ? <div className={css.orderHelp}>{subTitleText}</div> : null}
            </div>
            {totalReviewRating > 0 ? (
              <div className={css.strdv}>
                <StarRating rating={totalReviewRating} />{' '}
                <span className={css.reviewcont} onClick={onReviewClick}>
                  ({totalReviewContent} {totalReviewContent > 1 ? 'Reviews' : 'Review'})
                </span>
              </div>
            ) : null}
            {isCertifiedListing ? (
              <div className={css.certifiedBadgeContainer}>
                <CertifiedBadgeIcon />
                <span>Certified</span>
              </div>
            ) : null}

            <div className={css.author}>
              <AvatarSmall user={ensuredAuthor} className={css.providerAvatar} />
              <span className={css.providerNameLinked}>
                <FormattedMessage id="OrderPanel.author" values={{ name: authorLink }} />
              </span>
              <span className={css.providerNamePlain}>
                <FormattedMessage id="OrderPanel.author" values={{ name: authorDisplayName }} />
              </span>
              <div className={css.reviewSection}></div>
            </div>
            <SectionGallery
              listing={currentListing}
              variantPrefix={config.layout.listingImage.variantPrefix}
            />
            <div className={css.mobileHeading}>
              <div className={classNames(css.titlewithfv, css.orderPanelTitle)}>
                <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
              </div>
            </div>
            <SectionSocialMediaMaybe listing={currentListing} heading={'Social Media'} />
            <SectionTextMaybe heading={'About'} text={description} showAsIngress />
            {isSuperAdmin && certificate?.url ? (
              <SectionCertificateMayBe heading={'Certification'} text={certificate} showAsIngress />
            ) : null}
            <SectionBreedMayBe heading={'Breed/Species'} text={breed} showAsIngress />
            <SectionCastingBudgetMaybe heading={'Budget'} text={castingCallBudget} showAsIngress />
            <SectionDurationMaybe heading={'Duration'} duration={duration} showAsIngress />
            <SectionCastingDateMaybe
              heading={'Date'}
              date={date}
              dateNotDeclared={dateNotDeclared}
              listingCategory={listingCategory}
              showAsIngress
            />
            <SectionReelsMaybe heading={'Reels'} url={url} showAsIngress viewport={viewport} />

            <SectionDetailsMaybe
              publicData={publicData}
              metadata={metadata}
              listingConfig={listingConfig}
              intl={intl}
            />

            <div className={css.group}>
              <SectionCheckBoxGroupMaybe
                id={'color'}
                heading={'Color'}
                options={listingConfig.colorOptions}
                publicData={publicData}
              />

              <SectionCheckBoxGroupMaybe
                id={'size'}
                heading={'Size'}
                options={listingConfig.sizeOptions}
                publicData={publicData}
              />
            </div>

            <SectionCheckBoxGroupMaybe
              id={'traits'}
              heading={'Traits'}
              options={listingConfig.traitsOptions}
              publicData={publicData}
            />

            {/* {listingConfig.listingFields.reduce((pickedElements, config) => {
              const { key, enumOptions, scope = 'public' } = config;
              const value =
                scope === 'public' ? publicData[key] : scope === 'metadata' ? metadata[key] : null;
              const hasValue = value !== null;
              return hasValue && config.schemaType === SCHEMA_TYPE_MULTI_ENUM
                ? [
                    ...pickedElements,
                    <SectionMultiEnumMaybe
                      key={key}
                      heading={config?.showConfig?.label}
                      options={createFilterOptions(enumOptions)}
                      selectedOptions={value}
                    />,
                  ]
                : hasValue && config.schemaType === SCHEMA_TYPE_TEXT
                ? [
                    ...pickedElements,
                    <SectionTextMaybe key={key} heading={config?.showConfig?.label} text={value} />,
                  ]
                : pickedElements;
            }, [])} */}

            <SectionMapMaybe
              geolocation={geolocation}
              publicData={publicData}
              listingId={currentListing.id}
              mapsConfig={config.maps}
            />
            {reviews?.length > 0 ? (
              <SectionReviews
                id="reviewsSection"
                reviews={reviews}
                fetchReviewsError={fetchReviewsError}
              />
            ) : null}
            <SectionAuthorMaybe
              title={title}
              listing={currentListing}
              authorDisplayName={authorDisplayName}
              onContactUser={onContactUser}
              isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
              onCloseInquiryModal={() => setInquiryModalOpen(false)}
              sendInquiryError={sendInquiryError}
              sendInquiryInProgress={sendInquiryInProgress}
              onSubmitInquiry={onSubmitInquiry}
              currentUser={currentUser}
              onManageDisableScrolling={onManageDisableScrolling}
            />
            <SectionPackageMaybe
              heading={'Compare Packages'}
              publicData={publicData}
              intl={intl}
              history={history}
              slug={listingSlug}
              listingId={currentListing.id.uuid}
              listingState={currentListing.attributes.state}
              search={searchParams}
              tab={packageTabMOb}
              setTab={setPackageTabMob}
              viewport={viewport}
            />
          </div>
          <div className={css.orderColumnForProductLayout}>
            <OrderPanel
              className={css.productOrderPanel}
              listing={currentListing}
              isOwnListing={isOwnListing}
              onSubmit={handleOrderSubmit}
              viewport={viewport}
              authorLink={
                <NamedLink
                  className={css.authorNameLink}
                  name="ListingPage"
                  params={params}
                  to={{ hash: '#author' }}
                >
                  {authorDisplayName}
                </NamedLink>
              }
              title={<FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />}
              titleDesktop={
                <H4 as="h1" className={css.orderPanelTitle}>
                  <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                </H4>
              }
              author={ensuredAuthor}
              onManageDisableScrolling={onManageDisableScrolling}
              onContactUser={onContactUser}
              monthlyTimeSlots={monthlyTimeSlots}
              onFetchTimeSlots={onFetchTimeSlots}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              validListingTypes={config.listing.listingTypes}
              marketplaceCurrency={config.currency}
              dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
              marketplaceName={config.marketplaceName}
              searchParams={searchParams}
              listingSlug={listingSlug}
              packageTabMOb={packageTabMOb}
            />
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  listingConfig: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  listingConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const { adding } = state.FavouritesPage;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
    adding,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onAddOrRemoveFavourtites: (listingId, favourites) =>
    dispatch(addOrRemoveFavourites(listingId, favourites)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withViewport,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedListingPage);

export default ListingPage;
