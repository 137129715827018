import React, { useEffect, useState } from 'react';
import { Heading } from '../../components';
import ReactPlayer from 'react-player';
import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const SectionReelsMaybe = props => {
  const { url, heading, showAsIngress = false, viewport } = props;
  const [width, setWidth] = useState(viewport.width || 0);
  const [height, setHeight] = useState(viewport.width || 0);
  useEffect(() => {
    setWidth(viewport.width > 768 ? '640px' : '100%');
  }, [viewport.width]);
  useEffect(() => {
    setHeight(viewport.width > 768 ? '360px' : '640px');
  }, [viewport.height]);
  return url ? (
    <div className={css.sectionText}>
      {heading ? (
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {heading}
        </Heading>
      ) : null}
      {/* <video className={css.reelsvideo} width={width} height={height} controls>
        <source src={url} type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
      {height && width ? (
        <ReactPlayer
          className={css.reelsvideo}
          url={url}
          width={width}
          height={height}
          controls={true}
        />
      ) : null}
    </div>
  ) : null;
};

export default SectionReelsMaybe;
